
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Issue as PublicationSearchIssue, Paging } from '@components/search-results/publications-results/types'
import { Issue as ArticleIssue } from '@components/article-page/types'

@Component
export default class BibIssueFormat extends Vue {
  @Prop() publicationSearchIssue?: PublicationSearchIssue
  @Prop() articleIssue?: ArticleIssue
  @Prop() paging!: Paging
  @Prop({ default: false }) unsubbed!: boolean // If unsubbed, disable links. PublicGenericFormat should set unsubbed to true

  issue: ArticleIssue | null = null // ArticleIssue has more fields than PublicationSearchIssue, so some fields may be none

  mounted() {
    if (this.articleIssue) {
      this.issue = this.articleIssue
    }
    if (this.publicationSearchIssue) {
      this.issue = {
        id: -1, // Doesn't seem to be used for bib
        pubYearInt: this.publicationSearchIssue.pubYear,
        pubYear: this.publicationSearchIssue.pubYear,
        pubYear2: this.publicationSearchIssue.pubYear2,
        volume: this.publicationSearchIssue.volume,
        volume2: this.publicationSearchIssue.volume2,
        volume3: this.publicationSearchIssue.volume3,
        issNum: this.publicationSearchIssue.number,
        // issName?: string
        issn: '', // Doesn't seem to be used for bib
        journal: this.publicationSearchIssue.journal,
        // eIssn?: string
        volSlash: this.publicationSearchIssue.volSlash,
        isbn: this.publicationSearchIssue.isbn,
        collectionTitle: '', // Doesn't seem to be used for bib
        elementOrd: this.publicationSearchIssue.elementOrd,
        parenNum: this.publicationSearchIssue.parenNum,
      }
    }

    if (this.issue) {
      if (this.issue.elementOrd) {
        this.issue.elementOrd = this.issue.elementOrd.toUpperCase()
      } else {
        this.issue.elementOrd = 'R'
      }
    }
  }

  get serialLink() {
    if (this.issue.journal.id !== null) {
      return { name: 'SerialProfile', query: { journalId: this.issue.journal.id } }
    }
    // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
    return {
      name: 'JournalSearch',
      query: {
        query: `"${this.issue.journal.shortTitle}"`,
      },
    }
  }

  get volumeFormatted(): string {
    if (!this.issue.volume) {
      return ''
    }
    let result = `${this.issue.volume}`
    if (this.issue.volume2) {
      if (this.issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${this.issue.volume2})`
      } else {
        result = `${result}/${this.issue.volume2}`
      }
    }
    if (this.issue.volume3) {
      if (this.issue.volSlash.toLowerCase() === 'p') {
        result = `${result}(${this.issue.volume3})`
      } else {
        result = `${result}/${this.issue.volume3}`
      }
    }
    return result
  }

  get displayPaging() {
    let text = this.dashReplacement(this.paging.text)
    const endChar = text.charAt(text.length - 1)
    if (endChar !== ',' && endChar !== '.') {
      text += '.'
    }
    return text
  }

  get displayYearParen(): boolean {
    if (!this.issue) {
      return false
    }
    // Don't display paren if elementOrd == regular and volume and issue are empty
    if (this.issue.elementOrd === 'R' && this.issue.volume === null) {
      // MR4795809 - Don't put year in paren if Regular element order and volume is empty
      return false
    }
    if (this.volumeFormatted.length === 0 && this.issue.issNum === null && this.issue.issName === null) {
      return false
    }
    return true
  }

  dashReplacement(text: string) {
    text = text.replaceAll('---', '&mdash;')
    text = text.replaceAll('--', '&ndash;')
    return text
  }

  clearPunctuation(text: string) {
    // Clear trailing commas and periods, like in MR0507436
    text = this.dashReplacement(text)
    return text.replace(/\.$|,$/, '')
  }

  formatIssue(issue: string|number, multiIss: string|undefined = undefined) {
    // Some issue numbers include 'no. X,' which messes with the formatting
    let formatted = `${issue}`.replaceAll('no.', '').trim()
    formatted = this.clearPunctuation(formatted)
    if (multiIss) {
      formatted += '-'
      formatted += multiIss.replaceAll('no.', '').trim()
    }
    return formatted
  }

  formatSearchLink(limit:string) {
    let result = ''
    if (this.issue && this.issue.journal) {
      if (this.issue.journal.id !== null) {
        result += `ji:${this.issue.journal.id} `
      } else {
        // It's an old entry that doesn't have a journalId/serialId. Have to search for it instead.
        result += `j:"${this.issue.journal.shortTitle}" `
      }
    }
    if (limit === 'journal') {
      return result.trim() // .trim() added to help visited links show the correct color
    }
    if (limit === 'year') {
      // result += `y:${issue.pubYear} `
      result += `y:${this.issue.pubYearInt} `
      return result.trim()
    }
    if (this.issue && this.issue.volume) {
      result += `v:${this.issue.volume} `
    }
    if (this.limit === 'volume' || this.limit === 'vol') {
      return result.trim()
    }

    if (this.issue && this.issue.issNum) {
      result += `iss:${this.issue.issNum}`
    }
    if (limit === 'issue' || limit === 'iss') {
      return result.trim()
    }
    return result.trim()
  }
}
